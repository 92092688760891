import React from 'react';
import ReactDOM from 'react-dom';
import SmarteditController from '@/utils/Controllers/SmarteditController';

import MiniWishlistKEN from '@/components/organisms/MiniWishlistKEN';
import MiniCartKEN from '@/components/organisms/MiniCartKEN';

const initKENHeaderIcons = () => {
  const entries = SmarteditController.getEditorialEntries(
    '#js-r-entry-ken-header',
  );
  Array.from(entries).forEach(entry => {
    ReactDOM.render(
      <>
        <MiniWishlistKEN />
        <MiniCartKEN />
      </>,
      entry,
    );
  });
};

export default () => {
  initKENHeaderIcons();
};
