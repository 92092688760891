import LazySwiperController from '@/utils/Controllers/LazySwiperController';
import SmarteditController from '@/utils/Controllers/SmarteditController';
import _debounce from 'lodash/debounce';

export default () => {
  const COMPONENT_HOOK = '.js-ken-subcategorylist';

  const initSwiper = async (item, hasSideText) => {
    const nextEl = item.querySelector('.swiper-button-next');
    const prevEl = item.querySelector('.swiper-button-prev');
    const scrollbar = item.querySelector('.swiper-scrollbar');

    const totalSlides = item.querySelectorAll('.swiper-slide').length;

    const LazySwiperLib = await LazySwiperController();
    return new LazySwiperLib(item, {
      slidesPerView: hasSideText ? 5 : 7,
      spaceBetween: 20,
      loop: true,
      loopedSlides: totalSlides,
      grabCursor: true,
      slideToClickedSlide: true,
      scrollbar: {
        el: scrollbar,
      },
      navigation: { nextEl, prevEl },
      breakpoints: {
        1439: {
          slidesPerView: hasSideText ? 4 : 6,
          spaceBetween: 20,
        },
        1199: {
          slidesPerView: hasSideText ? 3 : 5,
          spaceBetween: 10,
        },
        1023: {
          slidesPerView: hasSideText ? 2.5 : 3.5,
          spaceBetween: 10,
        },
        767: {
          slidesPerView: 2,
          loop: false,
          noSwiping: false,
        },
      },
    });
  };

  const checkMedia = () => ({
    isBigDesktop: window.matchMedia('(min-width:1440px)').matches,
    isSmallDesktop: window.matchMedia(
      '(min-width:1200px) and (max-width:1439px)',
    ).matches,
    isBigTablet: window.matchMedia('(min-width:1024px) and (max-width:1199px)')
      .matches,
    isSmallTablet: window.matchMedia('(min-width:768px) and (max-width:1023px)')
      .matches,
    isMobile: window.matchMedia('(max-width:767px)').matches,
  });

  const addUrlToMobileCards = cards => {
    const { isMobile, isSmallTablet } = checkMedia();
    cards.forEach(card => {
      const cta = card.querySelector('.ken-titleimagelink__cta');
      if (cta) {
        card.addEventListener('click', () => {
          if (isMobile || isSmallTablet) {
            window.location.href = cta.href;
          }
        });
      }
    });
  };

  const sliderResponsive = (slides, hasSideText) => {
    const {
      isBigDesktop,
      isSmallDesktop,
      isBigTablet,
      isSmallTablet,
      isMobile,
    } = checkMedia();
    return (
      (isBigDesktop && !hasSideText && slides > 6) ||
      (isBigDesktop && hasSideText && slides > 4) ||
      (isSmallDesktop && !hasSideText && slides > 5) ||
      (isSmallDesktop && hasSideText && slides > 3) ||
      (isBigTablet && !hasSideText && slides > 4) ||
      (isBigTablet && hasSideText && slides > 2) ||
      (isSmallTablet && !hasSideText && slides > 3) ||
      (isSmallTablet && hasSideText && slides > 2) ||
      (isMobile && slides > 2)
    );
  };

  const createCarousel = () => {
    const entries = SmarteditController.getEditorialEntries(
      `${COMPONENT_HOOK}`,
    );

    [...entries].forEach(async entry => {
      let slider;
      const slidesCodesHashes = {};
      [...entry.querySelectorAll('.swiper-slide')].forEach(s => {
        const unique = s.getAttribute('data-code');
        if (!slidesCodesHashes[unique]) {
          slidesCodesHashes[unique] = true;
        } else {
          s.remove();
        }
      });
      const slides = entry.querySelectorAll('.swiper-slide');
      const hasSideText = !entry.classList.contains('js-noSideText');
      addUrlToMobileCards(slides);
      if (sliderResponsive(slides.length, hasSideText)) {
        entry.classList.remove('--noCarousel');
        slider = await initSwiper(entry, hasSideText);
      } else {
        entry.classList.add('--noCarousel');
      }
      window.addEventListener(
        'resize',
        _debounce(async () => {
          if (slider) {
            slider.destroy(true, true);
          }
          if (sliderResponsive(slides.length, hasSideText)) {
            entry.classList.remove('--noCarousel');
            slider = await initSwiper(entry, hasSideText);
          } else {
            entry.classList.add('--noCarousel');
          }
        }, 100),
      );
    });
  };
  SmarteditController.addOnReprocessPageListener(createCarousel);
  createCarousel();
};
