import LazySwiperController from '@/utils/Controllers/LazySwiperController';
import SmarteditController from '@/utils/Controllers/SmarteditController';

export default () => {
  // New create carousel function and smartedit update event callback
  const createCarousel = () => {
    const entries = SmarteditController.getEditorialEntries(
      '.js-ken-footerBenefitsWrapper',
    );
    Array.from(entries).forEach(async item => {
      const scrollbar = item.querySelector('.swiper-scrollbar');

      const LazySwiperLib = await LazySwiperController();
      return new LazySwiperLib(item, {
        slidesPerView: 3,
        resistanceRatio: 0,
        preloadImages: false,
        // Responsive breakpoints
        breakpoints: {
          // when window width is <= 767px
          767: {
            slidesPerView: 1,
          },
        },
        scrollbar: {
          el: scrollbar,
          hide: false,
        },
      });
    });
  };
  SmarteditController.addOnReprocessPageListener(createCarousel);
  createCarousel();
};
