import { checkWishlistIcon } from '@/kenwood/Controllers/KENWishlistController';
import initPdpAccessoriesController from '@/utils/Controllers/PdpAccessoriesController';
import SmarteditController from '@/utils/Controllers/SmarteditController';
import { LazySwiperLatest } from '@/utils/Controllers/LazySwiperLatestController';

const initAccessoriesCarousel = () => {
  // New create carousel function and smartedit update event callback
  const createCarousel = () => {
    const entries = SmarteditController.getEditorialEntries(
      '.js-ken-attachmentsWrapper',
    );
    Array.from(entries).forEach(async item => {
      const LazySwiperLatestLib = await LazySwiperLatest();
      const scrollbar = item.querySelector('.swiper-scrollbar');
      const prevEl = item.querySelector('.swiper-button-prev');
      const nextEl = item.querySelector('.swiper-button-next');
      return new LazySwiperLatestLib(item, {
        slidesPerView: 'auto',
        centerInsufficientSlides: true,
        resistanceRatio: 0,
        scrollbar: {
          el: scrollbar,
        },
        navigation: {
          prevEl,
          nextEl,
        },
      });
    });
  };
  SmarteditController.addOnReprocessPageListener(createCarousel);
  createCarousel();
};

const jsEventsCallback = () => {
  initAccessoriesCarousel();
  checkWishlistIcon();
};

export default () => {
  initPdpAccessoriesController(jsEventsCallback);
  initAccessoriesCarousel(); // this is needed since we have editorial components too
};
