import GigyaController from '@/utils/Controllers/Gigya/GigyaController';
import SmarteditController from '@/utils/Controllers/SmarteditController';
import {
  addDays,
  addHours,
  addMinutes,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  isAfter,
} from 'date-fns';

const calculateDate = endDate => {
  let startDate = new Date();
  if (isAfter(startDate, endDate)) return {};
  let days = differenceInDays(endDate, startDate);
  days = days < 10 ? `0${days}` : days;
  startDate = addDays(startDate, days);
  let hours = differenceInHours(endDate, startDate);
  hours = hours < 10 ? `0${hours}` : hours;
  startDate = addHours(startDate, hours);
  let minutes = differenceInMinutes(endDate, startDate);
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  startDate = addMinutes(startDate, minutes);
  let seconds = differenceInSeconds(endDate, startDate);
  seconds = seconds < 10 ? `0${seconds}` : seconds;
  return { days, hours, minutes, seconds };
};

const updateDate = entry => {
  const endDate = new Date(+entry.dataset.date);
  setInterval(() => {
    const dates = calculateDate(endDate);
    entry.querySelector('.--days').innerHTML = dates.days || '00';
    entry.querySelector('.--hours').innerHTML = dates.hours || '00';
    entry.querySelector('.--minutes').innerHTML = dates.minutes || '00';
    entry.querySelector('.--seconds').innerHTML = dates.seconds || '00';
  }, 1000);
};

const initCountdown = () => {
  const countdowns = SmarteditController.getEditorialEntries(
    '.js-ken-hero__counter',
  );
  Array.from(countdowns).forEach(entry => updateDate(entry));
};

const initKwClubSubscription = () => {
  const els = SmarteditController.getEditorialEntries(
    '.js-kwClub-subscription',
  );
  Array.from(els).forEach(el => {
    el.addEventListener('click', e => {
      e.preventDefault();
      GigyaController.setKWClubTouchpoints(true, el.href);
    });
  });
};

export default () => {
  SmarteditController.addOnReprocessPageListener(initCountdown);
  initCountdown();
  initKwClubSubscription();
};
