import KENHeaderNavigationAimController from './KENHeaderNavigationAimController';

class KENHeaderNavigation {
  header = document.querySelector('.ken-header');

  headerBandEl = document.querySelector('.js-ken-header__header-band');

  gapEl = document.querySelector('.js-ken-header__gap');

  stripe = this.header.querySelector('.ken-stripe__container');

  classHidden = '--hidden';

  classHeaderHidden = 'ken-header--hidden';

  classStripeHidden = '--stripe-hidden';

  classShrunk = '--shrunk';

  /*
   *  #####################
   *  ###  MOBILE VIEW  ###
   *  #####################
   */
  burgerButton = this.header.querySelector('#js-ken-header-burgerMenuButton');

  burgerButtonIcon = this.burgerButton.querySelector('i');

  menuDrawerContainer = this.header.querySelector(
    '.ken-header__navdrawer__mobile-container',
  );

  menuDrawersMobile = this.header.querySelectorAll(
    '.ken-header__navdrawer--mobile',
  );

  menuItemsMobile = this.header.querySelectorAll(
    '.ken-header__navdrawer--mobile .ken-header__navdrawer__nav-list__item',
  );

  menuTitlesMobile = this.header.querySelectorAll(
    '.ken-header__navdrawer__title',
  );

  mobileMainMenu = this.header.querySelector(
    '#js-ken-header-navdrawer-mobile-mainMenu',
  );

  isAnyMobileScreenOpen = () =>
    !this.menuDrawerContainer.classList.contains(this.classShrunk);

  openMenuScreenMobile = navigationItemId => {
    [...this.menuDrawersMobile].forEach(screen => {
      if (!screen.classList.contains(this.classShrunk)) {
        screen.classList.add(this.classShrunk);
      }
      if (screen.id === `js-ken-header-navdrawer-mobile-${navigationItemId}`) {
        screen.classList.remove(this.classShrunk);
      }
    });
  };

  attachMobileEventListeners = () => {
    [...this.mobileMainMenu.children].forEach(child => {
      child.style.width = `${this.header.offsetWidth}px`;
    });
    [...this.menuDrawersMobile].forEach(drawer => {
      [...drawer.children].forEach(child => {
        child.style.width = `${this.header.offsetWidth}px`;
      });
    });
    if (this.burgerButton) {
      this.burgerButton.addEventListener('click', () => {
        if (this.isAnyMobileScreenOpen()) {
          this.menuDrawerContainer.classList.add(this.classShrunk);
          setTimeout(() => {
            [...this.menuDrawersMobile].forEach(screen => {
              if (!screen.classList.contains(this.classShrunk)) {
                screen.classList.add(this.classShrunk);
              }
            });
            this.mobileMainMenu.classList.remove(this.classShrunk);
          }, 300);
          if (this.burgerButtonIcon.classList.contains('ken-icon--close')) {
            this.burgerButtonIcon.classList.remove('ken-icon--close');
            this.burgerButtonIcon.classList.add('ken-icon--hamburger');
          }
        } else {
          this.menuDrawerContainer.classList.remove(this.classShrunk);
          if (this.burgerButtonIcon.classList.contains('ken-icon--hamburger')) {
            this.burgerButtonIcon.classList.remove('ken-icon--hamburger');
            this.burgerButtonIcon.classList.add('ken-icon--close');
          }
        }
      });
    }

    [...this.menuTitlesMobile].forEach(title => {
      if (title.dataset.id && title.dataset.id !== '') {
        title.addEventListener('click', () => {
          this.openMenuScreenMobile(title.dataset.id);
        });
      }
    });

    [...this.menuItemsMobile].forEach(item => {
      if (item.dataset.id && item.dataset.id !== '') {
        item.addEventListener('click', () => {
          this.openMenuScreenMobile(item.dataset.id);
        });
      }
    });
  };

  navbarDesktop = this.header.querySelector('#js-ken-header__navbar-desktop');

  stickyFilterPLPMobile = document.querySelector(
    '.ken-plp__filters__mobile-bar__wrapper',
  );

  stickyFilterPLPDesktop = document.querySelector(
    '.ken-plp__filters__desktop-bar__wrapper',
  );

  stickyMenuPDP = document.querySelector('.js-ken-pdp__sticky');

  classFilterSticky = '--sticky';

  classFilterPlusHeader = '--plus-header';

  isDesktopMenuOpen = false;

  checkScrollSpeed = (settings => {
    settings = settings || {};

    let lastPos;
    let newPos;
    let timer;
    let delta;
    const delay = settings.delay || 1000; // in "ms" (higher means lower fidelity )

    function clear() {
      lastPos = null;
      delta = 0;
    }

    clear();

    return () => {
      newPos = window.scrollY;
      if (lastPos != null) {
        delta = newPos - lastPos;
      }
      lastPos = newPos;
      clearTimeout(timer);
      timer = setTimeout(clear, delay);
      return delta;
    };
  })();

  putStickyMenuUnderHeader = (menu, stickyClass = null) => {
    if (menu) {
      const checkStickyClass =
        !stickyClass || menu.classList.contains(stickyClass);
      if (
        checkStickyClass &&
        !menu.classList.contains(this.classFilterPlusHeader)
      ) {
        menu.classList.add(this.classFilterPlusHeader);
      }
    }
  };

  putStickyMenuOnTop = (menu, stickyClass = null) => {
    if (menu) {
      const checkStickyClass =
        !stickyClass || menu.classList.contains(stickyClass);
      if (
        checkStickyClass &&
        menu.classList.contains(this.classFilterPlusHeader)
      ) {
        menu.classList.remove(this.classFilterPlusHeader);
      }
    }
  };

  gapHandler = () => {
    if (this.gapEl) {
      const resizeObserver = new ResizeObserver(() => {
        this.gapEl.style.height = `${this.headerBandEl.offsetHeight}px`;
      });
      resizeObserver.observe(this.gapEl);
      this.gapEl.style.height = `${this.headerBandEl.offsetHeight}px`;
    }
  };

  /*
   *  #####################
   *  ###  MAIN METHOD  ###
   *  #####################
   */
  attachEventListeners = () => {
    this.navbarDesktop.addEventListener('mouseover', () => {
      this.isDesktopMenuOpen = true;
    });
    this.navbarDesktop.addEventListener('mouseleave', () => {
      this.isDesktopMenuOpen = false;
    });

    this.gapHandler();

    window.onscroll = () => {
      if (!this.isDesktopMenuOpen && !this.isAnyMobileScreenOpen()) {
        const scrollSpeed = this.checkScrollSpeed();
        if (scrollSpeed < -30 || window.scrollY === 0) {
          if (this.header.classList.contains(this.classHeaderHidden)) {
            this.header.classList.remove(this.classHeaderHidden);
            this.header.style.top = 0;
            document.body.classList.remove(this.classHeaderHidden);
          }
          this.putStickyMenuUnderHeader(
            this.stickyFilterPLPDesktop,
            this.classFilterSticky,
          );
          this.putStickyMenuUnderHeader(
            this.stickyFilterPLPMobile,
            this.classFilterSticky,
          );
          this.putStickyMenuUnderHeader(this.stickyMenuPDP);
        } else if (scrollSpeed > 0) {
          if (!this.header.classList.contains(this.classHeaderHidden)) {
            this.header.classList.add(this.classHeaderHidden);
            this.header.style.top = `-${this.headerBandEl.offsetHeight}px`;
            document.body.classList.add(this.classHeaderHidden);
          }
          this.putStickyMenuOnTop(
            this.stickyFilterPLPDesktop,
            this.classFilterSticky,
          );
          this.putStickyMenuOnTop(
            this.stickyFilterPLPMobile,
            this.classFilterSticky,
          );
          this.putStickyMenuOnTop(this.stickyMenuPDP);
        }
      }
      if (this.stripe) {
        if (window.scrollY === 0) {
          if (this.stripe.classList.contains(this.classHidden)) {
            this.stripe.classList.remove(this.classHidden);
          }
          if (
            this.menuDrawerContainer.classList.contains(this.classStripeHidden)
          ) {
            this.menuDrawerContainer.classList.remove(this.classStripeHidden);
          }
        } else {
          if (!this.stripe.classList.contains(this.classHidden)) {
            this.stripe.classList.add(this.classHidden);
          }
          if (
            !this.menuDrawerContainer.classList.contains(this.classStripeHidden)
          ) {
            this.menuDrawerContainer.classList.add(this.classStripeHidden);
          }
        }
      }
    };
    this.attachMobileEventListeners();
  };
}

export default () => {
  const header = document.querySelector('.ken-header');
  const isLite = header && header.classList.contains('-lite');

  if (header && !isLite) {
    const kenHeaderNavigation = new KENHeaderNavigation();
    kenHeaderNavigation.attachEventListeners();
    KENHeaderNavigationAimController();
  }
};
