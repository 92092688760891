import LazySwiperController from '@/utils/Controllers/LazySwiperController';
import SmarteditController from '@/utils/Controllers/SmarteditController';

export default () => {
  // New create carousel function and smartedit update event callback
  const createCarousel = () => {
    const entries = SmarteditController.getEditorialEntries(
      '.js-ken-headerStripeWrapper',
    );
    Array.from(entries).forEach(async item => {
      const nextEl = item.querySelector('.swiper-button-next');
      const prevEl = item.querySelector('.swiper-button-prev');

      const LazySwiperLib = await LazySwiperController();
      return new LazySwiperLib(item, {
        slidesPerView: 'auto',
        resistanceRatio: 0.85,
        centerInsufficientSlides: true,
        navigation: {
          nextEl,
          prevEl,
        },
        // Responsive breakpoints
        breakpoints: {
          // when window width is <= 767px
          767: {
            slidesPerView: 1,
          },
        },
      });
    });
  };
  SmarteditController.addOnReprocessPageListener(createCarousel);
  createCarousel();
};
