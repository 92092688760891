import '@/style/kenwood/Main.scss';

// components controller
import KENArticlesLinkedSliderController from '@/kenwood/Controllers/KENArticlesLinkedSliderController';
import KENAttachmentsComponentController from '@/kenwood/Controllers/KENAttachmentsComponentController';
import KENCategoryCarouselController from '@/kenwood/Controllers/KENCategoryCarouselController';
import KENCookWithUsController from '@/kenwood/Controllers/KENCookWithUsController';
import KENEditorialCardController from '@/kenwood/Controllers/KENEditorialCardController';
import KENEditorialSliderController from '@/kenwood/Controllers/KENEditorialSliderController';
import KENFooterBenefitsSliderController from '@/kenwood/Controllers/KENFooterBenefitsSliderController';
import KENHeaderController from '@/kenwood/Controllers/KENHeaderController';
import KENHeaderLinksStripeController from '@/kenwood/Controllers/KENHeaderLinksStripeController';
import KENHeaderNavigationController from '@/kenwood/Controllers/KENHeaderNavigationController';
import KENHeaderV2NavigationController from '@/kenwood/Controllers/KENHeaderV2NavigationController';
import KENHeroController from '@/kenwood/Controllers/KENHeroController';
import KENHeroParallaxController from '@/kenwood/Controllers/KENHeroParallaxController';
import KENHomepageCategorySliderController from '@/kenwood/Controllers/KENHomepageCategorySliderController';
import KENKeyFeaturesController from '@/kenwood/Controllers/KENKeyFeaturesController';
import KENProductMoodBoardController from '@/kenwood/Controllers/KENProductMoodBoardController';
import KENProductTilesController from '@/kenwood/Controllers/KENProductTilesController';
import KENPromoBannerSliderController from '@/kenwood/Controllers/KENPromoBannerSliderController';
import KENRelatedProductsController from '@/kenwood/Controllers/KENRelatedProductsController';
import KENSubCategoryListController from '@/kenwood/Controllers/KENSubCategoryListController';
import KENVideoController from '@/kenwood/Controllers/KENVideoController';
import KENWhatsInTheBoxController from '@/kenwood/Controllers/KENWhatsInTheBoxController';
import KENWishlistController from '@/kenwood/Controllers/KENWishlistController';
import HotSpotComponentInitiator from '@/utils/Controllers/HotSpot/HotSpotComponentInitiator';

document.addEventListener('DOMContentLoaded', () => {
  KENHeaderController();
  KENHeaderV2NavigationController();
  KENHeaderLinksStripeController();
});

window.addEventListener('load', () => {
  KENPromoBannerSliderController();
  KENFooterBenefitsSliderController();
  KENCategoryCarouselController();
  KENHeroController();
  KENHeroParallaxController();
  KENArticlesLinkedSliderController();
  KENAttachmentsComponentController();
  KENVideoController();
  KENEditorialCardController();
  KENProductTilesController();
  KENSubCategoryListController();
  KENProductMoodBoardController();
  KENHomepageCategorySliderController();
  KENKeyFeaturesController();
  KENWhatsInTheBoxController();
  KENCookWithUsController();
  KENWishlistController();
  KENHeaderNavigationController();
  KENRelatedProductsController();
  KENEditorialSliderController();
  HotSpotComponentInitiator();
});
