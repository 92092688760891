import LazySwiperController from '@/utils/Controllers/LazySwiperController';
import SmarteditController from '@/utils/Controllers/SmarteditController';

export default () => {
  const createCarousel = () => {
    const entries = SmarteditController.getEditorialEntries(
      '.js-ken-editorial-slider',
    );

    Array.from(entries).forEach(async item => {
      const nextEl = item.querySelector('.swiper-button-next');
      const prevEl = item.querySelector('.swiper-button-prev');
      const scrollbar = item.querySelector('.swiper-scrollbar');

      const LazySwiperLib = await LazySwiperController();
      return new LazySwiperLib(item, {
        spaceBetween: 1,
        slidesPerView: 1.3,
        centeredSlides: true,
        roundLengths: true,
        observer: true,
        observeSlideChildren: true,
        preloadImages: false,
        scrollbar: {
          el: scrollbar,
          hide: false,
        },
        navigation: { nextEl, prevEl },
        breakpoints: {
          767: {
            slidesPerView: 1,
            spaceBetween: 30,
            centeredSlides: true,
          },
        },
      });
    });
  };

  SmarteditController.addOnReprocessPageListener(createCarousel);
  createCarousel();
};
