/* eslint-disable no-new */
import LazySwiperController from '@/utils/Controllers/LazySwiperController';
import SmarteditController from '@/utils/Controllers/SmarteditController';

export default () => {
  // New create carousel function and smartedit update event callback
  const createCarousel = () => {
    const entries = SmarteditController.getEditorialEntries(
      '.js-ken-promobannerslider',
    );
    const topMobileWidthLimit = 767;
    const isMobile =
      window.innerWidth <= topMobileWidthLimit &&
      'ontouchstart' in document.documentElement;
    if (isMobile) {
      Array.from(
        document.getElementsByClassName('ken-promobannerslider'),
      ).forEach(elem => {
        elem.className += ' mobile-mode';
      });
    }
    Array.from(entries).forEach(async item => {
      const nextEl = item.querySelector('.swiper-button-next');
      const prevEl = item.querySelector('.swiper-button-prev');
      const scrollbar = item.querySelector('.swiper-scrollbar');

      const LazySwiperLib = await LazySwiperController();
      return new LazySwiperLib(item, {
        slidesPerView: 2.2,
        resistanceRatio: 0,
        centeredSlides: false,
        spaceBetween: 20,
        slideToClickedSlide: true,
        updateOnWindowResize: true,
        setWrapperSize: true,
        resizeObserver: true,
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
        preloadImages: false,
        // Responsive breakpoints
        breakpoints: {
          // when window width is <= 767px
          767: {
            slidesPerView: 1.1,
            spaceBetween: 10,
            slidesPerGroup: 1,
            centeredSlides: true,
            centeredSlidesBounds: true,
          },
        },
        scrollbar: {
          el: scrollbar,
          hide: false,
        },
        navigation: {
          nextEl,
          prevEl,
        },
      });
    });
  };
  SmarteditController.addOnReprocessPageListener(createCarousel);
  createCarousel();
};
