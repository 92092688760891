import SmarteditController from '@/utils/Controllers/SmarteditController';

const engageTile = entry => {
  const linkUrl = entry.dataset.linkurl;
  const linkTarget = entry.dataset.linktarget;
  entry.addEventListener('click', () => {
    if (
      linkUrl &&
      linkTarget &&
      window.innerWidth <= 767 &&
      'ontouchstart' in document.documentElement
    ) {
      if (linkTarget === '_self') {
        window.location.href = linkUrl;
      } else {
        window.open(linkUrl, linkTarget);
        return true;
      }
    }
    return false;
  });
};

const init = () => {
  const TILE_HOOK = '.js-ken-producttiles-linkedtile';
  const entries = SmarteditController.getEditorialEntries(TILE_HOOK);
  Array.from(entries).forEach(engageTile);
};

export default () => {
  SmarteditController.addOnReprocessPageListener(init);
  init();
};
