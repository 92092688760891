import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useIterateModifiers from '@/hooks/useIterateModifiers';

const ButtonIconKEN = ({
  modifiers,
  glyph,
  counter,
  linkUrl,
  linkText,
  onClick,
  onMouseEnter,
  onMouseLeave,
  drawerIsVisible,
  Drawer,
}) => {
  const modifiersString = useIterateModifiers('ken-button__', modifiers);
  const Tag = linkUrl ? 'a' : 'button';
  const { t } = useTranslation();

  return (
    <Tag
      className={`ken-button ${modifiersString} ${
        drawerIsVisible ? ' displayNotificationDrawer ' : ''
      }`}
      href={linkUrl || null}
      onClick={onClick || null}
      onMouseEnter={onMouseEnter || null}
      onMouseLeave={onMouseLeave || null}
      aria-label={t('wishlist:wishlist.page.title')}
    >
      <span className='ken-btnIcon__wrap'>
        <i className={`ken-icon ken-icon--${glyph}`} />
        {counter >= 1 && (
          <span className='ken-btnIcon__checkoutCounter'>
            <i className='ken-btnIcon__checkoutCounterText'>{counter}</i>
          </span>
        )}
      </span>
      {linkText && <span className='dln-btnIcon__text'>{linkText}</span>}
      <div style={{ display: drawerIsVisible ? '' : 'none' }}>
        <Drawer />
      </div>
    </Tag>
  );
};

ButtonIconKEN.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
  glyph: PropTypes.string,
  counter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  linkUrl: PropTypes.string,
  linkText: PropTypes.string,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  drawerIsVisible: PropTypes.bool,
  Drawer: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]),
};

ButtonIconKEN.defaultProps = {
  modifiers: [],
  glyph: null,
  counter: null,
  linkUrl: null,
  linkText: null,
  onClick: null,
  onMouseEnter: null,
  onMouseLeave: null,
  drawerIsVisible: false,
  Drawer: null,
};

export default ButtonIconKEN;
