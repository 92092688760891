/* eslint-disable no-new */
import LazySwiperController from '@/utils/Controllers/LazySwiperController';
import SmarteditController from '@/utils/Controllers/SmarteditController';

export default () => {
  const COMPONENT_HOOK = '.js-ken-editorialcard';

  // New create carousel function and smartedit update event callback
  const createCarousel = () => {
    const entries = SmarteditController.getEditorialEntries(
      `${COMPONENT_HOOK}`,
    );
    Array.from(entries).forEach(async item => {
      const nextEl = item.querySelector('.swiper-button-next');
      const prevEl = item.querySelector('.swiper-button-prev');
      const scrollbar = item.querySelector('.swiper-scrollbar');

      const LazySwiperLib = await LazySwiperController();
      return new LazySwiperLib(item, {
        slidesPerView: 'auto',
        spaceBetween: 20,
        centeredSlides: false,
        watchOverflow: true,
        slideToClickedSlide: true,
        updateOnWindowResize: true,
        preloadImages: false,
        // Responsive breakpoints
        breakpoints: {
          // when window width is <= 767px
          767: {
            slidesPerView: 2,
            spaceBetween: 10,
            centeredSlides: false,
            slideToClickedSlide: true,
          },
          265: {
            slidesPerView: 1,
            spaceBetween: 0,
            centeredSlides: true,
            slideToClickedSlide: true,
          },
        },
        scrollbar: {
          el: scrollbar,
          hide: false,
          draggable: true,
        },
        navigation: { nextEl, prevEl },
      });
    });
  };
  SmarteditController.addOnReprocessPageListener(createCarousel);
  createCarousel();
};
