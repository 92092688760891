import React, { Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import i18n from '@/i18n';
import { I18nextProvider, useTranslation } from 'react-i18next';
import EVENTS from '@/utils/Constants/Events';
import LINK from '@/utils/Constants/Link';
import { goToCart, goToCheckout } from '@/utils/Common/Redirects';

// import components
import ButtonCartKEN from '@/components/atoms/ButtonCartKEN';
import MiniCartCTA from '@/components/molecules/MiniCart/MiniCartCTA';
import MiniCartFooter from '@/components/molecules/MiniCart/MiniCartFooter';
import MiniCartEmpty from '@/components/molecules/MiniCart/MiniCartEmpty';
import MiniCartNotEmpty from '@/components/molecules/MiniCart/MiniCartNotEmpty';

const MiniCartDrawerKenwood = () => {
  const [miniCart, setMiniCart] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    DLG.EVE.on(EVENTS.CART.REFRESH, setMiniCart);

    return () => {
      DLG.EVE.off(EVENTS.CART.REFRESH, setMiniCart);
    };
  }, []);

  if (!miniCart) return null;

  const isMiniCartEmpty = !miniCart.entries
    ? true
    : miniCart.entries.length <= 0;

  const miniCartClass = `ken-miniCart dln-miniCart${
    isMiniCartEmpty ? ' dln-miniCart--empty' : ''
  }`;

  return (
    <div className={miniCartClass}>
      <div className='ken-miniCart__arrow dln-miniCart__arrow' />
      <div className='dln-miniCart__wrapper'>
        <div className='dln-miniCart__head'>
          <span
            className='dln-miniCart__mainTitle'
            dangerouslySetInnerHTML={{
              __html: t('cart:cart.miniCart.title', {
                items: isMiniCartEmpty ? '0' : miniCart.entries.length,
              }),
            }}
          />
        </div>
        {!isMiniCartEmpty ? (
          <>
            <MiniCartNotEmpty cart={miniCart} />
            <MiniCartFooter
              totalPrice={miniCart.totalPrice}
              deliveryMode={miniCart.deliveryMode}
            />
            <MiniCartCTA goToCart={goToCart} goToCheckout={goToCheckout} />
          </>
        ) : (
          <MiniCartEmpty />
        )}
      </div>
    </div>
  );
};

const MiniCartKEN = ({ glyph, modifiers }) => {
  const [counter, setCounter] = useState(null);
  const [drawerIsVisible, setDrawerIsVisible] = useState(false);

  const loadAddDrawer = () => {
    setDrawerIsVisible(true);
  };

  const loadRemoveDrawer = () => {
    setDrawerIsVisible(false);
  };

  const loadManageDrawer = e => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      e.stopPropagation();
      setDrawerIsVisible(true);
    } else if (e.key === 'ArrowUp' || e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      setDrawerIsVisible(false);
    } else if (e.key === ' ') {
      e.preventDefault();
      e.stopPropagation();
      setDrawerIsVisible(!drawerIsVisible);
    }
  };

  useEffect(() => {
    DLG.EVE.on(EVENTS.BALOON.UPDATE.CART, setCounter);
    DLG.EVE.emit(EVENTS.CART.GET);
  }, []);
  return (
    <ButtonCartKEN
      modifiers={modifiers.split(' ')}
      glyph={glyph}
      counter={counter}
      linkUrl={LINK.CART}
      onMouseEnter={loadAddDrawer}
      onMouseLeave={loadRemoveDrawer}
      onKeyDown={loadManageDrawer}
      drawerKeyDown={e => {
        if (e.key === 'Escape') {
          e.preventDefault();
          e.stopPropagation();
          loadRemoveDrawer();
        }
      }}
      drawerIsVisible={drawerIsVisible}
      Drawer={MiniCartDrawerKenwood}
    />
  );
};

MiniCartKEN.propTypes = {
  glyph: PropTypes.string,
  modifiers: PropTypes.string,
};

MiniCartKEN.defaultProps = {
  glyph: null,
  modifiers: '',
};

export default () => {
  const el = document.getElementById('js-r-entry-ken-minicart');
  if (
    !document.body.classList.contains('page-dlgCheckoutPage') &&
    el &&
    !el.hasChildNodes()
  ) {
    i18n.loadNamespaces(['cart']);
    return ReactDOM.createPortal(
      <I18nextProvider i18n={i18n}>
        <Suspense fallback={<div />}>
          <MiniCartKEN {...el.dataset} />
        </Suspense>
      </I18nextProvider>,
      el,
    );
  }
  return null;
};
