class KENHeaderV2Navigation {
  header = document.querySelector('.ken-header-v2');

  navItems = this.header.querySelectorAll('.js-ken-header-v2__nav-item');

  menuItems = this.header.querySelectorAll('.js-ken-header-v2__menu-item');

  burgerButton = this.header.querySelector('.ken-icon--hamburger');

  mobileNavItems = this.header.querySelectorAll(
    '.ken-header-v2__menu-mobile__nav-item',
  );

  mobileCustomerService = this.header.querySelector(
    '#js-ken-header-v2__menu-mobile__button--wishlist',
  );

  activeClass = '--active';

  inactiveClass = '--inactive';

  visibleClass = '--visible';

  addEventListenersMobile = () => {
    if (!this.burgerButton) return;

    this.burgerButton.addEventListener('click', () => {
      this.burgerButton.classList.toggle('--show-menu-mobile');
      this.burgerButton.classList.toggle('ken-icon--hamburger');
      this.burgerButton.classList.toggle('ken-icon--close-v2');
    });

    [...this.mobileNavItems].forEach(navItem => {
      const screen = navItem.querySelector('.ken-header-v2__menu-screen');
      if (!screen) return;

      navItem.addEventListener('click', event => {
        event.stopPropagation();
        navItem.classList.add('--show-screen');
      });

      screen.addEventListener('click', event => {
        event.stopPropagation();
      });

      const titleBox = screen.querySelector(
        '.ken-header-v2__menu-screen__title-box',
      );
      titleBox.addEventListener('click', () => {
        navItem.classList.remove('--show-screen');
      });
    });

    this.mobileCustomerService.addEventListener('click', event => {
      event.stopPropagation();
      this.mobileCustomerService.classList.add('--show-screen');
    });

    const screen = this.mobileCustomerService.querySelector(
      '.ken-header-v2__menu-screen',
    );
    screen.addEventListener('click', event => {
      event.stopPropagation();
    });

    const titleBox = screen.querySelector(
      '.ken-header-v2__menu-screen__title-box',
    );
    titleBox.addEventListener('click', () => {
      this.mobileCustomerService.classList.remove('--show-screen');
    });
  };

  addEventListenersDesktop = () => {
    if (!this.header || !this.navItems.length) return;

    const menuItemClass = '.ken-header-v2__menu-item';

    const showMenu = menuWrapper => {
      const menuElement = menuWrapper.querySelector(menuItemClass);
      menuWrapper.classList.remove(this.inactiveClass);
      menuWrapper.classList.add(this.activeClass);
      menuElement.classList.add(this.visibleClass);
    };

    const hideMenu = menuWrapper => {
      const menuElement = menuWrapper.querySelector(menuItemClass);
      menuWrapper.classList.add(this.inactiveClass);
      menuWrapper.classList.remove(this.activeClass);
      menuElement.classList.remove(this.visibleClass);
    };

    const fadeInMenu = menuWrapper => {
      const menuElement = menuWrapper.querySelector(menuItemClass);
      menuElement.style.transition = 'opacity 0.3s';
      menuWrapper.classList.remove(this.inactiveClass);
      menuWrapper.classList.add(this.activeClass);
      setTimeout(() => {
        menuElement.classList.add(this.visibleClass);
      }, 5);
    };

    const fadeOutMenu = menuWrapper => {
      const menuElement = menuWrapper.querySelector(menuItemClass);
      menuElement.style.transition = 'opacity 0.3s';
      menuElement.classList.remove(this.visibleClass);
      setTimeout(() => {
        menuWrapper.classList.remove(this.activeClass);
        menuWrapper.classList.add(this.inactiveClass);
      }, 300);
    };

    const switchMenu = (currentMenu, newMenu) => {
      if (currentMenu.index > newMenu.index) {
        showMenu(newMenu.element);
        fadeOutMenu(currentMenu.element);
      } else {
        fadeInMenu(newMenu.element);
        setTimeout(() => {
          hideMenu(currentMenu.element);
        }, 300);
      }
    };

    [...this.navItems].forEach(item => {
      item.addEventListener('click', () => {
        if (item.classList.contains(this.activeClass)) {
          fadeOutMenu(item);
          setTimeout(() => {
            [...this.navItems].forEach(nav => {
              const menuItem = nav.querySelector(menuItemClass);
              menuItem.classList.remove(this.visibleClass);
              nav.classList.remove(this.activeClass);
              nav.classList.remove(this.inactiveClass);
            });
          }, 300);
        } else if (item.classList.contains(this.inactiveClass)) {
          const currentMenu = {};
          const newMenu = {};
          [...this.navItems].forEach((nav, index) => {
            if (nav.classList.contains(this.activeClass)) {
              currentMenu.index = index;
              currentMenu.element = nav;
            }
            if (nav === item) {
              newMenu.index = index;
              newMenu.element = nav;
            }
          });
          if (!currentMenu || !newMenu) return;
          switchMenu(currentMenu, newMenu);
        } else {
          [...this.navItems].forEach(nav => {
            if (nav === item) return;
            nav.classList.add(this.inactiveClass);
          });
          fadeInMenu(item);
        }
      });
    });

    [...this.menuItems].forEach(item => {
      item.addEventListener('click', event => {
        event.stopPropagation();
      });
    });
  };

  init = () => {
    this.addEventListenersMobile();
    this.addEventListenersDesktop();
  };
}

export default () => {
  const header = document.querySelector('.ken-header-v2');
  if (!header) return;
  const kenHeaderV2 = new KENHeaderV2Navigation();
  kenHeaderV2.init();
};
