import MenuAim from '@/utils/Common/MenuAim';

const KENHeaderNavigationAimController = () => {
  const menus = document.querySelectorAll(
    '.js-ken-header__navdrawer__aside > .js-ken-header__navdrawer__nav-list',
  );

  const toggleActivate = (el, isActivate) => {
    const submenu = el.querySelector(
      `#js-ken-header-level-wrapper-desktop-${el.dataset.id}`,
    );
    if (submenu) {
      el.classList.toggle('--active', isActivate);
      submenu.classList.toggle('--visible', isActivate);
    }
  };

  const activate = el => toggleActivate(el, true);
  const deactivate = el => toggleActivate(el, false);
  const exitMenu = () => true;

  if (menus) {
    menus.forEach(menu => {
      MenuAim(menu, { activate, deactivate, exitMenu });
    });
  }
};

export default KENHeaderNavigationAimController;
