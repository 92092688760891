import SmarteditController from '@/utils/Controllers/SmarteditController';

const manageShow = pane => {
  const fullPane = pane.querySelector('.ken-whatsinthebox-js-showall');
  const handle = pane.querySelector('.ken-whatsinthebox-js-showhandle');
  if (fullPane) {
    pane.classList.remove('--expanded');
    fullPane.style.height = 0;
    handle.addEventListener('click', () => {
      const { scrollHeight } = fullPane;
      if (pane.classList.contains('--expanded')) {
        fullPane.style.height = 0;
      } else {
        fullPane.style.height = `${scrollHeight}px`;
      }
      pane.classList.toggle('--expanded');
    });
  }
};

const init = () => {
  const PANECONTEXT_HOOK = '.ken-whatsinthebox-js-expandhook';
  const panes = SmarteditController.getEditorialEntries(PANECONTEXT_HOOK);
  Array.from(panes).forEach(manageShow);
};

export default () => {
  SmarteditController.addOnReprocessPageListener(init);
  init();
};
