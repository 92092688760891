import LazySwiperController from '@/utils/Controllers/LazySwiperController';
import SmarteditController from '@/utils/Controllers/SmarteditController';
import _debounce from 'lodash/debounce';

export default () => {
  // New create carousel function and smartedit update event callback
  const createCarousel = () => {
    const entries = SmarteditController.getEditorialEntries(
      '.js-ken-key-features',
    );
    const isDesktop = window.matchMedia('(min-width:768px)').matches;

    Array.from(entries).forEach(async item => {
      const scrollbar = item.querySelector('.swiper-scrollbar');

      const LazySwiperLib = await LazySwiperController();
      let slider = new LazySwiperLib(item, {
        centeredSlides: true,
        slidesPerView: 1.3,
        spaceBetween: 30,
        slideToClickedSlide: true,
        preloadImages: false,
        scrollbar: {
          el: scrollbar,
          hide: false,
        },
      });

      if (isDesktop) {
        slider.destroy(true, true);
      }

      window.addEventListener(
        'resize',
        _debounce(() => {
          const desktop = window.matchMedia('(min-width:768px)').matches;
          if (desktop) {
            slider.destroy(true, true);
          } else if (slider.destroyed) {
            slider = new LazySwiperLib(item, {
              centeredSlides: true,
              slidesPerView: 1.3,
              spaceBetween: 30,
              slideToClickedSlide: true,
              preloadImages: false,
              scrollbar: {
                el: scrollbar,
                hide: false,
              },
            });
          }
        }, 100),
      );
    });
  };

  SmarteditController.addOnReprocessPageListener(createCarousel);
  createCarousel();
};
