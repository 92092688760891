import LazySwiperController from '@/utils/Controllers/LazySwiperController';
import SmarteditController from '@/utils/Controllers/SmarteditController';

export default () => {
  const COMPONENT_HOOK = '.js-ken-categorycarousel';

  // New create carousel function and smartedit update event callback
  const createCarousel = () => {
    const entries = SmarteditController.getEditorialEntries(
      `${COMPONENT_HOOK}`,
    );
    Array.from(entries).forEach(async item => {
      const nextEl = item.parentNode.querySelector('.swiper-button-next');
      const prevEl = item.parentNode.querySelector('.swiper-button-prev');

      const LazySwiperLib = await LazySwiperController();
      return new LazySwiperLib(item, {
        a11y: {
          enabled: true,
        },
        keyboard: {
          enabled: true,
          onlyInViewport: true,
        },
        slidesPerView: 5,
        spaceBetween: 15,
        centeredSlides: false,
        slideToClickedSlide: true,
        watchOverflow: true,
        updateOnWindowResize: true,
        // Responsive breakpoints
        breakpoints: {
          1024: {
            slidesPerView: 4,
          },
          // when window width is <= 767px
          767: {
            slidesPerView: 2.5,
            spaceBetween: 15,
            centeredSlides: false,
            slideToClickedSlide: true,
          },
          265: {
            slidesPerView: 1.8,
            spaceBetween: 15,
            centeredSlides: true,
            slideToClickedSlide: true,
          },
        },
        navigation: { nextEl, prevEl },
      });
    });
  };
  SmarteditController.addOnReprocessPageListener(createCarousel);
  createCarousel();
};
