import LazySwiperController from '@/utils/Controllers/LazySwiperController';
import SmarteditController from '@/utils/Controllers/SmarteditController';
import _debounce from 'lodash/debounce';

const COMPONENT_CLASS = 'js-ken-productMoodboard';
const COMPONENT_PRODUCTS_CLASS = 'js-ken-productMoodBoardWrapper';

const KENProductMoodBoardSliders = [];

const loadCarousels = async (entry, isMobile) => {
  const productsSlider = entry.querySelector(`.${COMPONENT_PRODUCTS_CLASS}`);
  const pagination = entry.querySelector('.swiper-pagination');

  if (!isMobile) {
    pagination.removeAttribute('style');
    pagination.classList = ['swiper-pagination'];
  }

  const LazySwiperLib = await LazySwiperController();
  const swiper = new LazySwiperLib(productsSlider, {
    a11y: {
      enabled: true,
    },
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
    spaceBetween: 20,
    preloadImages: false,
    pagination: {
      clickable: true,
      el: pagination,
      dynamicBullets: isMobile,
      renderBullet: (index, classname) => {
        const label = productsSlider.querySelectorAll('.swiper-slide')[index]
          .dataset.tabTitle;
        return `<button class="${classname}">${label}</button>`;
      },
    },
  });

  pagination
    .querySelectorAll('.swiper-pagination-bullet')
    .forEach((bullet, index) => {
      bullet.addEventListener('keydown', e => {
        if (e.code === 'Enter' || e.code === 'Space') {
          e.preventDefault();
          e.stopPropagation();
          swiper.slideTo(index);
          setTimeout(() => {
            const a = swiper.slides[index].querySelector('a');

            if (a) {
              a.focus();
            }
          }, 300);
        }
      });
    });

  swiper.slides.each((index, slide) => {
    slide.querySelectorAll('a').forEach(a => {
      a.addEventListener('focus', () => swiper.slideTo(index, 0));
    });
  });

  KENProductMoodBoardSliders.push(swiper);
};

const initAll = () => {
  KENProductMoodBoardSliders.forEach(slider => {
    slider.destroy(true, true);
  });
  KENProductMoodBoardSliders.splice(-KENProductMoodBoardSliders.length);
  const isMobile = window.matchMedia('(max-width:1023px)').matches;
  const entries = SmarteditController.getEditorialEntries(
    `.${COMPONENT_CLASS}`,
  );
  Array.from(entries).forEach(entry => loadCarousels(entry, isMobile));
};

export default () => {
  SmarteditController.addOnReprocessPageListener(initAll);
  initAll();
  window.addEventListener(
    'resize',
    _debounce(() => {
      initAll();
    }, 100),
  );
};
