import LazySwiperController from '@/utils/Controllers/LazySwiperController';
import SmarteditController from '@/utils/Controllers/SmarteditController';

const createCategorySlider = async item => {
  const isDesktop = () => window.matchMedia('(min-width:1024px)').matches;
  const isTablet = () =>
    window.matchMedia('(min-width:600px) and (max-width:1023px)').matches;
  const isMobile = () => window.matchMedia('(max-width:599px)').matches;
  const numberOfSlides = document
    .querySelector('.ken-categorycarousel--slide')
    .getAttribute('data-slides');
  const TOPCATEGORYCAROUSEL_HOOK = '.js-ken-topcategorycarousel';
  const SUBCATEGORYSLIDER_HOOK = '.js-ken-subcategoryslider';
  const nextEl = item
    .querySelector(TOPCATEGORYCAROUSEL_HOOK)
    .parentNode.querySelector('.swiper-button-next');
  const prevEl = item
    .querySelector(TOPCATEGORYCAROUSEL_HOOK)
    .parentNode.querySelector('.swiper-button-prev');

  const wrapper = item.querySelector(TOPCATEGORYCAROUSEL_HOOK)
    .firstElementChild;
  const topCategoryCarousel = SmarteditController.getEditorialEntries(
    TOPCATEGORYCAROUSEL_HOOK,
  );
  const subCategorySlider = SmarteditController.getEditorialEntries(
    SUBCATEGORYSLIDER_HOOK,
  );

  const newSwiperInit = {
    slidesPerView: 5,
    spaceBetween: 15,
    centeredSlides: true,
    loop: false,
    loopedSlides: numberOfSlides,
    watchOverflow: true,
    slideToClickedSlide: true,
    noSwiping: true,
    navigation: { nextEl, prevEl },
    breakpoints: {
      1023: {
        slidesPerView: 4,
      },
      767: {
        slidesPerView: 3,
      },
      599: {
        slidesPerView: 2,
        centeredSlides: false,
      },
    },
  };
  const LazySwiperLib = await LazySwiperController();
  let topCategoryCarouselSwiper = new LazySwiperLib(
    topCategoryCarousel,
    newSwiperInit,
  );

  const subCategorySliderSwiper = new LazySwiperLib(subCategorySlider, {
    slidesPerView: 1,
    spaceBetween: 0,
    autoHeight: true,
    grabCursor: false,
    noSwiping: false,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    breakpoints: {
      767: {
        noSwiping: true,
      },
    },
  });

  const swiperWrapper = document.querySelector('.js-ken-topcategorycarousel')
    .firstElementChild;

  const toggleCarouselClass = () => {
    if (
      (isDesktop() && numberOfSlides < 6) ||
      (isTablet() && numberOfSlides <= 3) ||
      (isMobile && numberOfSlides < 3)
    ) {
      wrapper.classList.add('centered');
      prevEl.classList.add('hidden');
      nextEl.classList.add('hidden');
      if (swiperWrapper) {
        swiperWrapper.classList.remove('sliderActive');
      }
    } else {
      wrapper.classList.remove('centered');
      if (swiperWrapper) {
        swiperWrapper.classList.add('sliderActive');
      }
      prevEl.classList.remove('hidden');
      nextEl.classList.remove('hidden');
    }
  };

  toggleCarouselClass();

  window.addEventListener('resize', toggleCarouselClass);

  if (!topCategoryCarouselSwiper.destroyed) {
    topCategoryCarouselSwiper.controller.control = subCategorySliderSwiper;
  }

  topCategoryCarouselSwiper.destroy(true, true);

  const swiperSlidesTop = document.querySelectorAll(
    '.ken-categorycarousel--slide',
  );

  const addActiveDefault = () => {
    swiperSlidesTop[0].classList.add('swiper-slide-active');
  };

  addActiveDefault();

  window.addEventListener('resize', () => {
    addActiveDefault();
  });

  swiperSlidesTop.forEach((element, indexTop) => {
    element.addEventListener('click', () => {
      subCategorySliderSwiper.slideTo(indexTop);
      const el = document.querySelector('.js-ken-topcategorycarousel');
      const act = el.getElementsByClassName('swiper-slide-active');
      const manageActiveClass = () => {
        Array.from(act).forEach(active => {
          active.classList.remove('swiper-slide-active');
        });
      };

      manageActiveClass();
      element.classList.add('swiper-slide-active');

      window.addEventListener('resize', () => {
        manageActiveClass();
        element.classList.add('swiper-slide-active');
      });
    });
  });

  const swiperCreate = () => {
    if (
      numberOfSlides > 5 ||
      (isMobile() && numberOfSlides > 2) ||
      (isTablet() && numberOfSlides > 3)
    ) {
      topCategoryCarouselSwiper = new LazySwiperLib(
        topCategoryCarousel,
        newSwiperInit,
      );
      topCategoryCarouselSwiper.controller.control = subCategorySliderSwiper;
    }
  };

  swiperCreate();

  window.addEventListener('resize', () => {
    topCategoryCarouselSwiper.destroy(true, true);
    addActiveDefault();
    swiperCreate();
  });
};

const init = () => {
  const COMPONENT_HOOK = '.js-ken-homepagecategoryslider';
  const entries = SmarteditController.getEditorialEntries(COMPONENT_HOOK);
  Array.from(entries).forEach(createCategorySlider);
};

export default () => {
  SmarteditController.addOnReprocessPageListener(init);
  init();
};
